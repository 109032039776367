<template>
  <ion-spinner v-bind="$attrs"
               name="crescent" />
</template>

<script>
import { IonSpinner } from '@ionic/vue'

export default {
  components: { IonSpinner },
}
</script>
