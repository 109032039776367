<template>
  <ion-header>
    <ion-toolbar color="primary">
      <ion-title>
        {{ title }}
      </ion-title>
      <ion-buttons slot="end">
        <gro-spinner v-if="loading" />
        <ion-button @click="dismiss">
          <ion-icon slot="start" name="close" />
        </ion-button>
      </ion-buttons>
    </ion-toolbar>
    <slot />
  </ion-header>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      required: false,
    },
    loading: {
      required: false,
    },
  },
  methods: {
    async dismiss () {
      await this.$dismissModal()
    },
  },
  mounted () {
    document.addEventListener('backbutton', this.dismiss, false)
  },
  beforeUnmount () {
    document.removeEventListener('backbutton', this.dismiss, false)
  },
}
</script>
