<template>
  <div ref="svgRef"
       class="animated-svg-container"
       :style="style"></div>
</template>

<script>
import Vivus from 'vivus'

export default {
  props: {
    src: {
      type: String,
      required: true,
    },
    height: {
      type: String,
      default: '50vw',
    },
    type: {
      type: String,
      default: 'scenario',
    },
    duration: {
      type: Number,
      default: 60,
    },
    animation: {
      type: String,
      default: 'EASE',
      validator (value) {
        return [
          'LINEAR', 'EASE', 'EASE_OUT', 'EASE_IN', 'EASE_OUT_BOUNCE',
        ].indexOf(value) !== -1
      },
    },
    looped: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    style () {
      return {
        height: this.height,
      }
    },
  },
  mounted () {
    const scope = this
    new Vivus(this.$refs.svgRef, {
      file: this.$asset(this.src),
      type: this.type,
      duration: this.duration,
      animTimingFunction: Vivus[this.animation],
    }, function () {
      if (this.duration < 2) {
        this.stop()
        return
      }
      if (!scope.looped || this.type === 'scenario') return
      this.reset()
      this.play()
    })
  },
}
</script>
<style lang="scss" >
.svg-canada {
  fill:rgba(255, 255, 255, 0.75);
  animation: fadeAnimation 3s infinite;
  animation-delay:1.5s;
}
.svg-usa {
  fill:rgba(255, 255, 255, 0.75);
  animation: fadeAnimation 3s infinite;
}

@keyframes fadeAnimation {
  0%   { opacity:1; }
  50%  { opacity:0.75; }
  100% { opacity:1; }
}

</style>
