<template>
  <ion-fab vertical="bottom"
           horizontal="end"
           slot="fixed">
    <ion-fab-button v-bind="$attrs"
                    :disabled="disabled"
                    @click="$emit('click')">
      <ion-icon :name="icon"></ion-icon>
    </ion-fab-button>
  </ion-fab>
</template>

<script>
export default {
  emits: ['click'],
  props: {
    icon: {
      type: String,
      default: 'add',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
}
</script>
