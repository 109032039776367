<template>
  <div class="progress-bar">
    <div class="progress" :class="{animated: animated}" :style="progressStyle" />
  </div>
</template>

<script>
export default {
  props: {
    from: {
      type: Number,
      required: false,
      default: 0,
    },
    to: {
      type: Number,
      required: false,
      default: 100,
    },
    value: {
      type: Number,
      required: false,
      default: 0,
    },
    animated: {
      type: Boolean,
      required: false,
      default: true,
    },
  },
  computed: {
    widthPercentage () {
      const diff = this.to - this.from
      if (diff === 0) {
        return 0
      }

      const value = Math.max(this.from, Math.min(this.value, this.to))
      return (value / diff) * 100.0
    },
    progressStyle () {
      return `width: ${this.widthPercentage}%`
    },
  },
}
</script>

<style>
.progress-bar {
  height: 5px;
  background-color: var(--ion-color-primary-contrast);
}

.progress {
  height: 5px;
  background-color: var(--ion-color-primary);
}

.animated {
  transition: width 500ms;
}
</style>
