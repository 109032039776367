import AnimatedSvg from './AnimatedSvg'
import ConnectionLastSeen from './ConnectionLastSeen'
import GroButton from './GroButton'
import GroFab from './GroFab'
import GroProgress from './GroProgress'
import GroSpinner from './GroSpinner'
import ModalFooter from './ModalFooter'
import ModalHeader from './ModalHeader'
import MomentFrom from './MomentFrom'
import Skeleton from './Skeleton'

import {
  IonBadge,
  IonButton,
  IonButtons,
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonCardTitle,
  IonCheckbox,
  IonContent,
  IonDatetime,
  IonCol,
  IonFab,
  IonFabButton,
  IonFabList,
  IonFooter,
  IonGrid,
  IonHeader,
  IonIcon,
  IonItem,
  IonItemDivider,
  IonInput,
  IonLabel,
  IonList,
  IonListHeader,
  IonMenu,
  IonMenuToggle,
  IonModal,
  IonNav,
  IonNote,
  IonPage,
  IonRange,
  IonRadio,
  IonRadioGroup,
  IonReorder,
  IonReorderGroup,
  IonRow,
  IonSearchbar,
  IonSegment,
  IonSegmentButton,
  IonSelect,
  IonSelectOption,
  IonText,
  IonTextarea,
  IonThumbnail,
  IonTitle,
  IonToggle,
  IonToolbar,
  IonRouterOutlet,
  IonApp,
  IonSkeletonText,
} from '@ionic/vue'

// Common components are available throughout the application through the Components plugin
export default {
  AnimatedSvg,
  ConnectionLastSeen,
  GroButton,
  GroFab,
  GroProgress,
  GroSpinner,
  IonBadge,
  IonButton,
  IonButtons,
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonCardTitle,
  IonCheckbox,
  IonContent,
  IonCol,
  IonDatetime,
  IonFab,
  IonFabButton,
  IonFabList,
  IonFooter,
  IonGrid,
  IonHeader,
  IonIcon,
  IonItem,
  IonItemDivider,
  IonInput,
  IonLabel,
  IonList,
  IonListHeader,
  IonMenu,
  IonMenuToggle,
  IonModal,
  IonNav,
  IonNote,
  IonPage,
  IonRange,
  IonRadio,
  IonRadioGroup,
  IonReorder,
  IonReorderGroup,
  IonRow,
  IonSearchbar,
  IonSegment,
  IonSegmentButton,
  IonSelect,
  IonSelectOption,
  IonText,
  IonTextarea,
  IonThumbnail,
  IonTitle,
  IonToggle,
  IonToolbar,
  IonRouterOutlet,
  IonApp,
  IonSkeletonText,
  ModalFooter,
  ModalHeader,
  MomentFrom,
  Skeleton,
}
