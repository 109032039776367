<template>
  <span class="moment-from">
    <template v-if="date">
      {{ momentFrom }}
    </template>
    <template v-else>
     {{ $t('common.No data') }}
    </template>
  </span>
</template>

<script>
import moment from 'moment'

export default {
  data () {
    return {
      updateInterval: null,
    }
  },
  props: {
    date: {
      required: true,
    },
    period: {
      type: Number,
      required: false,
      default: 10 * 1000,
    },
  },
  computed: {
    momentFrom () {
      if (!this.date) return
      const date = moment(this.date)
      const days = moment().diff(date, 'days')
      if (days < 1) return date.fromNow()
      if (days < 30) return this.$t('common.duration.Days', { days })
      return this.$t('common.duration.Over 30 days')
    },
  },
  mounted () {
    // force an update every period in order to refresh the moment's from description
    this.updateInterval = setInterval(() => {
      this.$forceUpdate()
    }, this.period)
  },
  beforeUnmount () {
    clearInterval(this.updateInterval)
  },
}
</script>
