<template>
  <div class="skeleton" :style="style" />
</template>

<script>
export default {
  props: {
    height: {
      required: false,
      default: '0.7em',
    },
    width: {
      required: false,
      default: '100%',
    },
  },
  computed: {
    style () {
      return {
        height: this.height,
        width: this.width,
      }
    },
  },
}
</script>

<style>
.skeleton {
  display: inline-block;
  background: linear-gradient(
      90deg,
      rgba(255, 255, 255, 0.1),
      rgba(100, 100, 100, 0.25),
      rgba(255, 255, 255, 0.1)
  );
  background-size: 600% 600%;
  border-radius: 2px;
  animation: skeleton-loading 1.5s ease infinite;
}

@keyframes skeleton-loading {
  0%,
  to {
    background-position: 0 50%;
  }
  50% {
    background-position: 100% 50%;
  }
}

</style>
