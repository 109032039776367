<template>
  <ion-footer>
    <ion-toolbar class="seamless">
      <ion-row>
        <ion-col v-if="!noBack"
                 :push="reverseButtons ? 6 : undefined"
                 class="seamless">
          <gro-button @click="onBackClick"
                      :color="backColor"
                      class="seamless"
                      expand="full"
                      :disabled="backDisabled">
            {{ backText }}
          </gro-button>
        </ion-col>
        <ion-col v-if="!noPrimary"
                 :pull="reverseButtons ? 6 : undefined"
                 class="seamless">
          <ion-button class="seamless"
                      @click="onPrimaryClick" expand="full"
                      :color="primarySuccess ? 'success' : 'primary'"
                      :disabled="primaryDisabled || busy">
            <gro-spinner v-if="busy" slot="start" />
            {{ primaryText }}
          </ion-button>
        </ion-col>
      </ion-row>
    </ion-toolbar>
  </ion-footer>
</template>

<script>
export default {
  emits: ['backClick', 'primaryClick'],
  data () {
    return {
      busy: false,
      busyTimer: null,
    }
  },
  props: {
    backLabel: {
      type: String,
    },
    primaryLabel: {
      type: String,
    },
    noBack: {
      type: Boolean,
    },
    backDisabled: {
      type: Boolean,
    },
    backColor: {
      type: String,
      default: 'light',
    },
    noPrimary: {
      type: Boolean,
    },
    primarySuccess: {
      type: Boolean,
    },
    primaryDisabled: {
      type: Boolean,
    },
    busyTimeout: {
      type: Number,
      default: 10 * 1000,
    },
    reverseButtons: {
      type: Boolean,
    },
  },
  computed: {
    primaryText () {
      if (this.primaryLabel) return this.primaryLabel
      return this.$t('common.Done')
    },
    backText () {
      if (this.backLabel) return this.backLabel
      return this.$t('common.Back')
    },
  },
  methods: {
    onBackClick () {
      if (this.$attrs.onBackClick) {
        this.$emit('backClick')
      } else {
        this.$goBack()
      }
    },
    onPrimaryClick () {
      this.busy = true
      this.busyTimer = setTimeout(() => {
        this.busy = false
      }, this.busyTimeout)

      try {
        this.$emit('primaryClick')
      } catch (e) {
        this.busy = false
        this.clearBusyTimer()
        throw e
      }
    },
    clearBusyTimer () {
      clearTimeout(this.busyTimer)
    },
  },
  beforeUnmount () {
    this.clearBusyTimer()
  },
}
</script>
