<template>
  <div class="last-seen">
    <div v-if="inCreatedState && countdownTime">
      <ion-icon name="wifi"/>
      {{ $t('common.connection.Connecting within', { countdownTime }) }}
    </div>
    <div v-if="inExpectingDataState && countdownTime">
      <ion-icon name="time"/>
      {{ $t('common.connection.Expecting data in', { countdownTime }) }}
    </div>
    <div v-else-if="details && hasProblem">
      <div>
        <ion-icon name="warning" color="danger"/>
        <moment-from :date="lastSeen" class="ion-padding-start"/>
      </div>
      <div>
        <ion-text color="warning" class="text-problem">
          {{ $t('common.connection.Problem') }}
        </ion-text>
      </div>
    </div>
    <div v-else-if="hasProblem">
      <ion-icon name="warning" color="danger"/>
    </div>
    <div v-else>
      <moment-from :date="lastSeen"/>
    </div>
  </div>
</template>

<script>
import moment from 'moment'

export default {
  emits: ['update:hasProblem'],
  data () {
    return {
      countdownTo: null,
      countdownTime: null,
      countdownInterval: null,
    }
  },
  props: {
    lastSeen: {
      required: true,
    },
    createdAt: {
      required: true,
    },
    hasData: {
      type: Boolean,
      required: true,
    },
    details: {
      type: Boolean,
    },
    firstConnectionMinutes: {
      type: Number,
      default: 5,
    },
    expectingDataMinutes: {
      type: Number,
      default: 5.5,
    },
    maxDataIntervalMinutes: {
      type: Number,
      default: 60,
    },
  },
  watch: {
    lastSeen: {
      handler () {
        if (this.inCreatedState) {
          this.startCountdown(this.createdAt, this.firstConnectionMinutes)
        } else if (this.inExpectingDataState) {
          this.startCountdown(this.lastSeen, this.expectingDataMinutes)
        }
      },
      immediate: true,
    },
    hasProblem: {
      handler (hasProblem) {
        this.$emit('update:hasProblem', hasProblem)
      },
      immediate: true,
    },
  },
  computed: {
    inCreatedState () {
      return (!this.lastSeen && this.createdAt) || moment(this.lastSeen).isBefore(this.createdAt)
    },
    inExpectingDataState () {
      return this.lastSeen && !this.hasData
    },
    hasProblem () {
      if (this.inCreatedState) {
        return !this.countdownTime
      }

      if (this.inExpectingDataState) {
        return !this.countdownTime
      }

      if (!this.lastSeen) {
        return true
      }

      // 'normal state'
      const recent = moment().subtract(this.maxDataIntervalMinutes, 'minutes')
      return recent.isAfter(this.lastSeen)
    },
  },
  methods: {
    startCountdown (from, minutes) {
      this.stopCountdown()
      this.countdownTo = moment(from).add(minutes, 'minutes')

      this.updateCountdownTimer()
      this.countdownInterval = setInterval(() => {
        this.updateCountdownTimer()
      }, 1000)
    },

    stopCountdown () {
      clearInterval(this.countdownInterval)
      this.countdownTime = null
    },

    updateCountdownTimer () {
      const timeLeft = moment.duration(this.countdownTo.diff())
      if (this.countdownTo.diff() < 1) {
        this.countdownTime = null
        return
      }

      const minutes = '' + Math.floor(timeLeft.asMinutes())
      const seconds = '' + timeLeft.seconds()
      this.countdownTime = minutes.padStart(2, '0') + ':' + seconds.padStart(2, '0')
    },
  },
  beforeUnmount () {
    this.stopCountdown()
  },
}
</script>
<style scoped>
ion-icon {
  vertical-align: middle;
}

.text-problem {
  font-weight: 500;
}
</style>
